@tailwind base;
@tailwind components;
@tailwind utilities;

/* ==== Fonts ==== */

@font-face {
    font-family: 'GalanoBlack';
    src: url('./fonts/GalanoGrotesque-Black.otf');
}

@font-face {
    font-family: 'GalanoHeavy';
    src: url('./fonts/GalanoGrotesque-Heavy.otf');
}

@font-face {
    font-family: 'GalanoMedium';
    src: url('./fonts/GalanoGrotesque-Medium.otf');
}

@font-face {
    font-family: 'GalanoRegular';
    src: url('./fonts/GalanoGrotesque-Regular.otf');
}

/* ==== Global Styles ==== */

*,
::before,
::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
    font-size: 16px;
    font-family: 'GalanoRegular';
}

h1,
h2,
h3,
h4,
h5,
h6,
p {

    margin: 0;
}

img {
    width: 100%;
    height: auto;
    display: block;
}

/* ==Scroll bar==*/

body::-webkit-scrollbar {
    width: 0.25rem;
}

body::-webkit-scrollbar-track {
    background: #1e1e24;
}

body::-webkit-scrollbar-thumb {
    background: #2D61F1;
}

/* == Slider ==*/

.swiper-button-next:after {
    content: none !important;
}

.swiper-button-prev:after {
    content: none !important;
}

.swiper-button-next {
    background-image: url(./images/arrow-right.png);
    opacity: 1 !important;
    opacity: 1 !important;
    background-repeat: no-repeat;
    width: 22px !important;
    background-size: contain;
    margin: 0 15px;
}

.swiper-button-prev {
    background-image: url(./images/arrow-left.png);
    opacity: 1 !important;
    opacity: 1 !important;
    background-repeat: no-repeat;
    width: 22px !important;
    background-size: contain;
    margin: 0 15px;
}